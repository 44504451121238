import React from 'react';
import { Box } from '@springcare/sh-component-library';

import type { BoxProps } from '@springcare/sh-component-library';

/**
 * A simple card component, mainly to quickly swap out with the @smeargle Card component in lieu of
 * major redesigns.
 */
export const Card = ({ children, ...rest }: BoxProps) => (
  <Box borderWidth={1} borderRadius={8} borderColor="border-bold" p={8} {...rest}>
    {children}
  </Box>
);
